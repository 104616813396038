.notificationbar {
    position: static;
    background-color: yellow;
    padding: .1%;
    margin-top: 0px;
    width: 100vw;
    min-height: 30px;
    border-bottom: 1px solid goldenrod;
    display: inline-block;
}

.homenotificationbar {
    position: static;
    background-color: yellow;
    padding: .1%;
    margin-top: -15px;
    width: 100vw;
    min-height: 30px;
    height: auto;
    border-bottom: 1px solid goldenrod;
    display: inline-block;
}

.homepage {
    width: 100vw;
}

.notificationtext {
    padding-top: 40px;
    font-size: 100%;
}

.mainText {
    text-align: center;
    width: 100%;
    height: 83vh;
    position: relative;
    display: flex;
    align-items: center;
    background-color: black;
    padding-top: 6%;
    z-index: -1;
}

#HomeHeader {
    font-size: 3em;
    color: black;
}

#homebutton {
    transform: scale(1.3);
    margin-top: 1%;
    color: black;
    background-color: white;
}

#homebutton:hover {
    transform: scale(1.35);
    color: white;
    background-color: black;
}

h1 {
    float: left;
    font-size: 100%;
}

h4 {
    font-size: 46px;
    margin-bottom: 30px;
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white, 5px 5px 0 black;
    padding-top: 30px;
    border-bottom: 2px solid white;
}

.loginbutton {
    height: 25px;
    width: 100px;
    text-align: center;
    margin-left: 90%;
}

.loginbutton a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
    display: inline-block;
}

.loginbutton a:hover {
    color: white; /* Hover color */
    box-shadow: inset 5 5 5px black;
    height: 25px;
    width: 100px;
    background-color: goldenrod;
    text-align: center;
    position: relative;
    border-radius: 20px;
}

.loginbutton:hover:active {
    transform: scale(1.05);
}

#logintext {
    padding: .5%;
    font-size: 100%;
    margin-left: -15px;
    margin-top: 40px;
    width: 160px;
}

body {
    margin: auto;
    overflow-x: hidden;
}

.BodyText {
    z-index: 3;
    justify-content: center;
    align-items: center;
    top: 45vh;
    right: 20%;
    left: 20%;
    position: absolute;
    text-align: center;
}

.bg-Video {
    object-fit: cover;
    width: 250vh;
    padding-top: 43vh;
    filter: blur(10px);
    opacity: 0.5;
}

.navbar {
    background-color: rgb(255, 255, 255, .9);
    position: static;
    top: 0;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Drop Shadow */
}

#navbar-logo {
    float: left;
    margin-right: 2%;

}

.navbar-links {
    list-style: none;
    display: left;
    margin-right: 1%;
    margin-left: 1%;
    padding-right: 1%;
}

.navbar-links li {
    display: inline;
}

.navbar-links a {
    text-decoration: none;
    color: #333;
    font-size: 100%;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #007BFF; /* Hover color */
    transform: scale(1.1);
}

.navbar-links:hover:active {
    transform: scale(1.05);
}

h2 {
    font-size: 3em;
    font-family: 'ITC Kabel Std', sans-serif;
}

.rides-container {
    overflow-y: auto; /* Allow vertical scrolling */
}

.about-us-container {
    overflow-y: auto; /* Allow vertical scrolling */
}

.profile-container {
    overflow-y: auto; /* Allow vertical scrolling */
}