.h01 {
    font-size: 180%;
    padding-bottom: 0.5%;
    padding-top: 1%;

}

.tablebox {
    width: 100%;
}

.InformationTable {
    width: 90%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 3px 3px rgb(155, 155, 155);
    text-align: center;
    padding: 1%;
}

input {
    margin: .5%;
}

.buttons-mod {
    padding: 1%;
    margin: 1%;
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5%;
    width: 10%;
}

.buttons-mod-del {
    padding: 1%;
    margin: 1%;
    background-color: #d6414e; /* Green */
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5%;
    width: 10%;
}

label {
    margin: 2%;
}

.Create {
    background-color: #04AA6D;
}

table {
    width: 20vh;
    border-spacing: 15px;
    color: #444;
    background-color: white;
}

tr {
    margin-bottom: 20px;
}

th {
    width: 22vh;
    text-align: left;
    font-size: 20px;
}

td {
    width: 30vh;

}