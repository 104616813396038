#login-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.loginbox { /* taken directly from six flags */
    max-height: 100%;
    display: block;
    margin: 0;
    width: 400px;
    background: white;
    height: auto;
    padding: 10px 30px 30px 30px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px -2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: auto;
    max-width: 400px;
    text-align: center;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
