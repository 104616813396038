.back-button:hover {
    background-color: gray; /* Darker shade on hover */
}

.back-button {
    float: left;
    margin-bottom: 0px; /* Space below the button */
    padding: 10px 20px; /* Padding around the button */
    background-color: white; /* Button background color */
    color: black; /* Button text color */
    text-decoration: underline;
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
}

.banner-image3 {
    align-items: center;
    flex-direction: column;
    width: 100vm;
    background-image: url('../images/login-a.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    box-shadow: 5px 5px 0 lightgrey;
    overflow: visible;
}

.h4 {
    color: black;
    font-size: 46px;
    margin-bottom: 30px;
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white, 5px 5px 0 black;
    padding-top: 30px;
    border-bottom: 2px solid white;
    text-align: center;
}

#topquote_one {
    color: black;
    font-size: 1.5em;
    text-align: center;
}

h2 {
    color: black;
    text-shadow: -3px -3px 0 white, 3px -3px 0 white, -3px 3px 0 white, 3px 3px 0 white, 5px 5px 0 black;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
    line-height: 48px;
}

.events-container {
    padding: 20px;
    max-width: 100vw;
    margin: auto;
    height: 85vh; /* Adjust based on your navbar height */
    overflow-y: auto; /* Allow vertical scrolling */
    color: white;
}

.events-category {
    margin-bottom: 10px; /* Space between categories */
    color: white;
}

.events-list {
    width: 99%;
    gap: 20px;
}

.events-card {
    background-color: #f9f9f9; /* Card background color */
    border: 1px solid #ddd; /* Card border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding inside cards */
    width: 100%; /* Adjust width based on how many cards you want in a row */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    color: black; /* Change text color to black */
    text-align: left;
    margin-bottom: 1%;
}

.events-card:hover {
    transform: scale(1.01);
}

.events-card h3 {
    margin-top: 0; /* Remove margin from the top of the card title */
    color: black; /* Change card title color to black */
}

.events-card p {
    color: black; /* Change paragraph text color to black */
}
