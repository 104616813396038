.containermain {
    padding-bottom: 3%;
    overflow-y: auto;
}

.notificationbar {
    position: static;
    background-color: yellow;
    padding: .1%;
    margin-top: 0px;
    width: 100vw;
    min-height: 30px;
    border-bottom: 1px solid goldenrod;
    display: inline-block;
}

.notificationtext {
    padding-top: 40px;
    font-size: 100%;
}

.background-image {
    position: fixed;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url('../images/login-b.jpg');
    background-size: cover; /* Scale the image to cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    z-index: -2;
    width: 100%;
    height: 100%;
    opacity: 1;
    filter: blur(4px);
    overflow-y: auto;
}

.ticket-container {
    padding: 20px;
    max-width: 100vw;
    margin: auto;
    color: white;
}

.h4-ticket {
    color: black;
    font-size: 46px;
    margin-bottom: 30px;
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white, 5px 5px 0 black;
    padding-top: 30px;
    border-bottom: 2px solid white;
    text-align: center;
}

.ticket-category {
    margin-bottom: 40px; /* Space between categories */

}

.ticket-list {
    display: flex; /* Use flexbox for the ride cards */
    flex-wrap: wrap; /* Wrap cards to the next line if needed */
    gap: 20px; /* Space between cards */
    justify-content: center;
}

.ticket-card {
    background-color: #f9f9f9; /* Card background color */
    border: 1px solid #ddd; /* Card border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding inside cards */
    width: 360px; /* Adjust width based on how many cards you want in a row */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    color: black; /* Change text color to black */
    height: 32em;
    margin-top: 2%;
    margin-right: 4%;
    margin-left: 4%;
    position: relative;
}

.price {
    bottom: 10%;
    position: absolute;
}

.button {
    color: white;
    background-color: #04AA6D; /* Green */
    border: none;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3%;
    width: 50%;
    height: 10%;
    text-align: center;
    margin: 5%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.button2 {
    color: white;
    background-color: #ff1e56;
    border: none;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3%;
    width: 50%;
    height: 10%;
    text-align: center;
    margin: 5%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.PleaseLogin {
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3%;
    width: 100%;
    text-align: left;
    margin: 5%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.button:hover {
    transform: scale(1.05);
}

.ticket-card h3 {
    margin-top: 0; /* Remove margin from the top of the card title */
    color: black; /* Change card title color to black */
}

.ticket-card:hover {
    transform: scale(1.02);
}


.ticket-card p {
    color: black; /* Change paragraph text color to black */
}