#login-bg {
    width: 100vm;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-wrap: wrap;
    z-index: -1;
    opacity: 0.7;
}

.loginbox {
    /* taken directly from six flags */
    max-height: 100%;
    display: block;
    margin: 0;
    width: 400px;
    background: white;
    height: auto;
    padding: 10px 30px 30px 30px;
    /* margin-left: auto;
    margin-right: auto; */
    margin: 0 auto;
    box-shadow: 0px 0px 6px -2px;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: auto;
    max-width: 400px;
    text-align: center;
    overflow-y: auto;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
}

.form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.form-container h2 {
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #45a049;
}

table {
    border: 1px solid black;
    table-layout: fixed;
    width: 200px;
    text-align: center;
}

th,
td {
    font-size: 1em;
    border: 1px solid black;
    width: 100px;
    overflow: hidden;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination li:hover {
    background-color: #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination li.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    border-color: #007bff;
}

.pagination li a {
    text-decoration: none;
    color: inherit;
}

.break-me {
    margin: 0 5px;
    color: #999;
}

.pagination li.disabled {
    pointer-events: none;
    color: #ccc;
    background-color: #f5f5f5;
}